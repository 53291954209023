import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

/**
 * Core lib imports
 */
import { environment } from '../../../../environments/environment';
import { EntityService, Entities, CoreResponse } from '@ratkaiga/core';

@Injectable()
export class AuthService {

  constructor(private http: HttpClient) { }

  /**
   * Meghívjuk a bejelentkezés végpontot a kapott adatokkal. A visszatérésünk egy AuthServerResponseEntity lesz, mert ez egy 
   * speciális végpont.
   *
   * @param email string
   * @param password string
   * @returns Observable<Entities.AuthServerResponseEntity>
   */
  login(email: string, password: string): Observable<Entities.ScrollAuthServerResponse> {

    let params: HttpParams = new HttpParams();
    params = params.set('email', email);
    params = params.set('password', password);
    params = params.set('client', environment.client_id.toString());
    
    return this.http.post<Entities.ScrollAuthServerResponse>(`${environment.endpoints.app}/auth/password-token/printportal`, params, {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
    });
  }

  /**
   * Meghívjuk a profil végpontot, hogy a felhasználó adatait lekérhessük. A visszatérésünk egy BaseResponse lesz, hiszen 
   * egy Core választ várunk.
   * 
   * @returns Observable<BaseResponse>
   */
  getUserByToken(): Observable<CoreResponse> {

    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Authorization', 'Bearer ' + localStorage.getItem(environment.localStorage.tokenStorage));
    return this.http.get<CoreResponse>(`${environment.endpoints.auth}/auth/info`, { headers: httpHeaders });
  }

  /**
   * Dummy - csak azért, hogy legyen
   * 
   * @param user 
   */
  register(user: Entities.ScrollUserProfile): Observable<any> {

    let params = new HttpParams();
    params = params.set('client', environment.client);
    params = params.set('first_name', user.getFirstName());
    params = params.set('last_name', user.getLastName());
    params = params.set('email', user.getEmail());
    params = params.set('password', user.getPassword());

    return this.http.post<CoreResponse>(`${environment.endpoints.app}/user/register`, params, {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
    })
      .pipe(
        map((res: CoreResponse) => {
          return res;
        }),
        catchError(this.handleError('registration', []))
      );
  }

  /**
   * Dummy - csak azért, hogy legyen
   * 
   * @param user 
   */
  public requestPassword(email: string): Observable<any> {
    return this.http.get(`${environment.endpoints.app}/user/forgot?=${email}`)
      .pipe(catchError(this.handleError('forgot-password', []))
      );
  }

  /**
   * Dummy - csak azért, hogy legyen
   * 
   * @param user 
   */
  updateUser(_user: Entities.ScrollUserProfile): void { 
    //
  }

  /**
   * Dummy - csak azért, hogy legyen
   * 
   * @param user 
   */
  createUser(user: Entities.ScrollUserProfile): void { 
    //
  }

  addPostOptions(): HttpHeaders {

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem(environment.localStorage.tokenStorage));

    return headers;
  }


  /**
   * Lekezeljük, ha probléma volt a lekérdezéssel
   *
   * @param operation string
   * @param result any
   */
  private handleError<T>(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {

      if (error instanceof HttpErrorResponse) {
        // eslint-disable-next-line no-prototype-builtins
        if (error.hasOwnProperty('error')) {
          result = error.error;
        }
      }

      // TODO: log küldés a szerver felé és vagy bármilyen központi logolási megoldás
      console.error(error);

      // hagyjuk futni az appot, azzal az értékkel amit kapunk a resultban
      return of(result);
    };
  }
}
